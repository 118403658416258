import ParentCss from "../../style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Async from "utils/Async";
import BusinessesActions from "actions/BusinessesActions";
import Constants from "const/Constants";
import Countries from "const/Countries";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Step from "../Step";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const SyncEntitiesStep = (props) => {
  const history = useHistory();

  const {
    index: stepIndex,
    currentStep,
    connectedService,
    rpaMode,
    setNewBusinessId
  } = props;

  const dispatch = useDispatch();

  const integrationServiceData = IntegrationServices.getByValue(connectedService);

  const { code: envVarsCode, error: envVarsError } = useMemo(() => {
    return Utils.parseQueryString(window.location.search);
  }, []);

  const { messages, uiTexts } = useSelector(getTextsData);

  const { countryCode } = useSelector(getActiveOrganization);

  const usCountry = countryCode === Countries.US;

  const xeroService = connectedService === IntegrationServices.XERO.value;

  const quickBooksService = connectedService === IntegrationServices.QUICK_BOOKS.value;

  const zohoService = connectedService === IntegrationServices.ZOHO.value;

  const [dataSyncTextA] = messages.onboardingSyncEntities;

  const [integrationSyncStep, setIntegrationSyncStep] = useState(0);

  const integrationSteps = useMemo(() => {
    if (!connectedService) return [];

    return [
      [
        IntegrationsActions.integrateBusinesses,
        quickBooksService ? uiTexts.syncingBusiness : uiTexts.syncingBusinesses,
        Icons.Briefcase
      ],
      [IntegrationsActions.syncAccounts, uiTexts.syncingAccounts, Icons.CreditCard],
      ...(rpaMode
        ? []
        : [
          [IntegrationsActions.syncClasses, uiTexts.syncingClasses, Icons.Stack],
          (xeroService || quickBooksService) && [IntegrationsActions.syncItems, uiTexts.syncingItems, Icons.Cube],
          (xeroService || zohoService || (quickBooksService && !usCountry))
        && [IntegrationsActions.syncTaxRates, uiTexts.syncingTaxRates, Icons.Percent],
          quickBooksService && [IntegrationsActions.syncLocations, uiTexts.syncingLocations, Icons.MapPin],
          [IntegrationsActions.syncContacts, quickBooksService
            ? uiTexts.syncingContactsAndProjects : uiTexts.syncingContacts, Icons.Users],
          zohoService && [IntegrationsActions.syncProjects, uiTexts.syncingProjects, Icons.Kanban],
          [
            IntegrationsActions.syncTransactions,
            Utils.replaceTextVars(
              uiTexts.syncingTransactionsFromDate,
              { date: moment().subtract(1, "years").startOf("year").format(Constants.DATETIME_FORMATS.DATE_TEXT) }
            ),
            Icons.Repeat
          ]
        ])
    ].filter(Boolean);
  }, [connectedService, rpaMode, usCountry, xeroService, quickBooksService, zohoService, uiTexts]);

  const runIntegrationSteps = useCallback(async() => {
    let newBusinessIds = [];

    await Async.runInSequence(integrationSteps.map(([action], index) => {
      return async() => {
        if (index) {
          if (newBusinessIds.length) {
            setIntegrationSyncStep(index);
            await dispatch(action(connectedService, newBusinessIds, true, true, true));
          }
        } else {
          let selectedItems = null;

          let extraData = { rpaMode: !!rpaMode };

          if (integrationServiceData.manualSelection) {
            const { businesses: serviceBusinessesList, ...restResponseData } = await dispatch(action(connectedService, true));

            extraData = restResponseData;
            if (Array.isArray(serviceBusinessesList) && serviceBusinessesList.length) {
              if (serviceBusinessesList.length > 1) {
                const modalResult = await dispatch(UiActions.showSelectIntegrationBusinessesWindow({
                  businessesList: serviceBusinessesList,
                  service: connectedService
                }));

                selectedItems = modalResult || [];
              } else selectedItems = [serviceBusinessesList[0].itemId];
            }
          }

          const { businesses: businessesData } = await dispatch(action(connectedService, false, selectedItems, extraData));

          if (Array.isArray(businessesData) && businessesData.length) newBusinessIds = businessesData.map(({ id }) => id);
        }
      };
    }));

    const [businessId] = newBusinessIds;

    if (!businessId) {
      const allBusinessesData = await dispatch(BusinessesActions.fetchBusinessesList(false, true, true));

      history.push(allBusinessesData.length === 1 ? `/${allBusinessesData[0].id}` : UiRoutes.MAIN);

      return;
    }

    if (!rpaMode && quickBooksService) {
      setNewBusinessId(businessId);
    } else {
      history.push(newBusinessIds.length === 1 ? `/${businessId}` : UiRoutes.MAIN);
    }
  }, [
    connectedService,
    integrationServiceData,
    integrationSteps,
    quickBooksService,
    rpaMode,
    history,
    dispatch,
    setNewBusinessId
  ]);

  const [, statusText, StatusIcon] = (
    integrationSteps[integrationSyncStep]
    || [null, uiTexts.settingUp, Icons.Spinner]
    || []);

  useEffect(() => {
    if (!integrationSteps.length) return;

    if (envVarsCode) {
      if (!envVarsError) runIntegrationSteps();
    } else {
      setIntegrationSyncStep(-1);
    }
  }, [envVarsCode, envVarsError, integrationSteps.length, runIntegrationSteps]);

  return (
    <Step
      number={stepIndex + 1}
      active={currentStep === stepIndex}
      success={currentStep > stepIndex}>
      <div className={ParentCss.row}>
        <div className={ParentCss.col}>
          <div className={ParentCss.subtitle}>
            {dataSyncTextA}
          </div>
          {(currentStep === stepIndex) && (
            <>
              <div className={classNames(ParentCss.progress, ParentCss.animated)}>
                <div />
              </div>
              {!!statusText && (
                <div className={ParentCss.description}>
                  <StatusIcon data-wait={integrationSteps[integrationSyncStep] ? undefined : ""} />
                  <span>{statusText}</span>
                </div>
              )}
            </>
          )}
        </div>
        {(currentStep > stepIndex) && (
          <div className={`${ParentCss.likeButton} ${ParentCss.success}`}>
            <Icons.Check weight="bold" />
            <span>{uiTexts.synced}</span>
          </div>
        )}
      </div>
    </Step>
  );
};

export default React.memo(SyncEntitiesStep);

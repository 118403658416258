import Css from "./style.module.scss";

import demoBackground from "assets/demoBackground.jpg";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserData, getUserRestrictions } from "selectors/user";
import { useSelector } from "react-redux";
import AddNewBusiness from "nlib/common/AddNewBusiness";
import PostSignUp from "nlib/common/PostSignUp";
import React from "react";

const NoBusinessData = () => {
  const { uiTexts, errors } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const userData = useSelector(getUserData);

  const userRestrictions = useSelector(getUserRestrictions);

  const { currency, countryCode } = activeOrganization || {};

  if ((activeOrganization.billingId && !activeOrganization.subscriptionId) || userData.holdUser) {
    return (
      <div
        className={Css.noBusinessData}
        style={{ backgroundImage: `linear-gradient(rgba(0, 49, 102, 0.3), rgba(0, 49, 102, 0.3)), url(${demoBackground})` }}>
        <PostSignUp />
      </div>
    );
  }

  if (!currency || !countryCode || userRestrictions.businessesCreate) {
    const [accessDeniedErrorA, accessDeniedErrorB] = errors.accessDenied;

    return (
      <div className={Css.noBusinessData}>
        <div className={Css.errorContainer}>
          <Icons.Warning weight="thin" className={Css.icon} />
          <div className={Css.title}>{accessDeniedErrorA}</div>
          <div className={Css.description}>{accessDeniedErrorB}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={Css.noBusinessData}
      style={{ backgroundImage: `linear-gradient(rgba(0, 49, 102, 0.3), rgba(0, 49, 102, 0.3)), url(${demoBackground})` }}>
      <div className={Css.centered}>
        <div className={Css.card}>
          <h5><b>{uiTexts.connectNewBussiness}</b></h5>
          <AddNewBusiness />
        </div>
      </div>
    </div>
  );
};

export default React.memo(NoBusinessData);

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { ONBOARDING_STEPS } from "./lib/Step";
import { getSelectedBusinessData, getUserBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import ActivityActions from "actions/ActivityActions";
import AddQuickBooksDesktopStep from "./lib/AddQuickBooksDesktopStep";
import BusinessDescriptionStep from "./lib/BusinessDescriptionStep";
import BusinessesActions from "actions/BusinessesActions";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import InviteUserStep from "./lib/InviteUserStep";
import QuickBooksCompleteStep from "./lib/QuickBooksCompleteStep";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import RpaSettingUpStep from "./lib/RpaSettingUpStep";
import RpaSettingsStep from "./lib/RpaSettingsStep";
import SelectFrequencyStep from "./lib/SelectFrequencyStep";
import SelectServiceStep from "./lib/SelectServiceStep";
import SyncEntitiesStep from "./lib/SyncEntitiesStep";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import WhoAskedStep from "./lib/WhoAskedStep";
import classNames from "classnames";

const {
  STEP_FIRST,
  STEP_SECOND,
  STEP_THIRD,
  STEP_FOURTH,
  STEP_FIFTH,
  STEP_SIXTH,
  STEP_SEVENTH,
  STEP_EIGHTH
} = ONBOARDING_STEPS;

const Onboarding = (props) => {
  const { service: connectedService, rpaMode } = props;

  const dispatch = useDispatch();

  const [newBusinessId, setNewBusinessId] = useState(null);

  const { error: envVarsError } = useMemo(() => {
    return Utils.parseQueryString(window.location.search);
  }, []);

  const { uiTexts, messages } = useSelector(getTextsData);

  const userRole = useSelector(getUserRole);

  const allUserBusinessesData = useSelector(getUserBusinessesData);

  const [serviceToConnect, setServiceToConnect] = useState(null);

  const {
    id: selectedBusinessId,
    extraData = {},
    settings: {
      rpaFrequency,
      rpaStartFrom,
      rpaTransactionTypes,
      autoAskClient,
      businessDescription
    } = {}
  } = useSelector(getSelectedBusinessData);

  const { integrationService, lastParsingRpaAt, lastReconciliationRpaAt } = extraData;

  const quickBooksService = (connectedService || integrationService) === IntegrationServices.QUICK_BOOKS.value;

  const currentStep = useMemo(() => {
    const {
      lastEntitiesAutoSetAt,
      uncategorizedRpaEnabled,
      reconciliationRpaEnabled,
      integrationServiceConnected
    } = extraData;

    if (!connectedService && integrationService && !integrationServiceConnected) return STEP_FIRST;
    if ((uncategorizedRpaEnabled || reconciliationRpaEnabled) && autoAskClient !== undefined) return STEP_EIGHTH;
    if (autoAskClient !== undefined) return STEP_SEVENTH;
    if (rpaStartFrom !== undefined && rpaTransactionTypes !== undefined) return STEP_SIXTH;
    if (rpaFrequency) return STEP_FIFTH;
    if (businessDescription !== undefined) return STEP_FOURTH;
    if (selectedBusinessId && (rpaMode || (newBusinessId && lastEntitiesAutoSetAt))) return STEP_THIRD;
    if ((selectedBusinessId || connectedService) && !envVarsError) return STEP_SECOND;

    return STEP_FIRST;
  }, [
    rpaMode,
    extraData,
    envVarsError,
    rpaStartFrom,
    rpaFrequency,
    autoAskClient,
    connectedService,
    integrationService,
    newBusinessId,
    businessDescription,
    selectedBusinessId,
    rpaTransactionTypes
  ]);

  const stepsComponents = useMemo(() => {
    return [
      SelectServiceStep,
      serviceToConnect === IntegrationServices.QUICK_BOOKS_DESKTOP.value
        ? AddQuickBooksDesktopStep : SyncEntitiesStep,
      ...(
        rpaMode
          ? [
            BusinessDescriptionStep,
            SelectFrequencyStep,
            RpaSettingsStep,
            WhoAskedStep,
            InviteUserStep,
            RpaSettingUpStep
          ]
          : [quickBooksService && currentStep === STEP_THIRD && QuickBooksCompleteStep])
    ].filter(Boolean);
  }, [serviceToConnect, rpaMode, quickBooksService, currentStep]);

  const [onboardingTitle] = rpaMode ? messages.onboardingRpaWorkflow : messages.onboardingRegularWorkflow;

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const showToAllClientsButton = !businessUser && allUserBusinessesData.length > 1;

  const rpaDidRun = !!(lastParsingRpaAt || lastReconciliationRpaAt) && (autoAskClient !== undefined);

  const handleBackButtonClick = useCallback(() => {
    window.location.href = UiRoutes.MAIN;
  }, []);

  useEffect(() => {
    if (selectedBusinessId) {
      return Utils.setInterval(() => {
        dispatch(BusinessesActions.fetchBusiness(selectedBusinessId, true, true));
        dispatch(ActivityActions.fetchActivityList({ backgroundUpdate: true }));
      }, Constants.DATA_LIST_UPDATE_INTERVAL);
    }

    return () => {};
  }, [selectedBusinessId, dispatch]);

  return (
    <div className={classNames(Css.onboarding, rpaDidRun && currentStep === STEP_FOURTH && Css.reconnect)}>
      <div className={Css.container}>
        <div className={Css.card}>
          <div className={Css.title}>
            <div>{onboardingTitle}</div>
            {showToAllClientsButton && <Button light large outline className={Css.closeButton} onClick={handleBackButtonClick}>
              <Icons.ArrowLeft />
              <span>{uiTexts.back}</span>
            </Button>}
          </div>
          {stepsComponents.map((StepComponent, index) => {
            return (
              <StepComponent
                key={String(`${index}`)}
                index={index}
                rpaMode={rpaMode}
                currentStep={currentStep}
                connectedService={connectedService || integrationService}
                serviceToConnect={serviceToConnect}
                newBusinessId={newBusinessId}
                setNewBusinessId={setNewBusinessId}
                setServiceToConnect={setServiceToConnect}
                onSetServiceToConnect={setServiceToConnect} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Onboarding);
